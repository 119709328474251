import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);
window.UIkit = UIkit

const gallery = document.querySelector(".blocks-gallery-grid");

if (gallery) {
    gallery.setAttribute('uk-lightbox', "");
}

ymaps.ready(init);

function init() {
    const myMap = new ymaps.Map("map", {
        center: [45.327777, 37.285105],
        zoom: 17,
        controls: []
    });
    myMap.controls.add('zoomControl');
    const placemark = new ymaps.Placemark([45.327777, 37.285105], {
        hintContent: 'ст. Голубицкая, ул. Курортная, 34',
        balloonContent: '<strong>Гостиница GOLDLEO</strong> ст. Голубицкая, ул. Курортная, 34'
    }, {
        iconColor: '#0095b6',
        iconLayout: 'default#image',
        iconImageHref: '/wp-content/uploads/2021/10/favicon.svg',
        iconImageOffset: [-12, -12]
    });
    myMap.geoObjects.add(placemark);
    myMap.behaviors.disable('scrollZoom')
}



